<template>
    <section class="stories" v-scroll-reveal>

        <nav class="stories__nav">
            <ul>
                <li @click="$emit('update:isLatestNews', true)" :class="{ 'active': isLatestNews }">latest stories</li>
                <li @click="$emit('update:isLatestNews', false)" :class="{ 'active': !isLatestNews }">speaking engagements</li>
            </ul>
        </nav>

        <!-- Speaking Engagements -->
        <transition name="pop-fade">

            <div class="stories__engagements" v-if="!isLatestNews && data">
                <div class="stories__engagements__engagement" v-for="(story, i) in data.speaking_engagements" :key="story.id">
                    <router-link :to="story.slug" append>
                        <div class="eng__asset" v-scroll-reveal="{ distance: '50px', origin: 'left', delay: 0, opacity: 0}">
                            <div class="eng__image" :style="'background-image: url(' + story.asset.path + ')'" v-if="story.asset.type == 'image'"></div>
                            <div class="eng__video" v-else>
                                <div class="video-wrap">
                                    <video autoplay muted loop playsinline>
                                        <source type="video/mp4" :src="story.asset.path">
                                    </video>
                                </div>
                            </div>
                        </div>
                        <div class="eng__date" v-scroll-reveal="{ distance: '50px', origin: 'right', delay: 250, opacity: 0}">
                            <p class="month">{{story.date.month}}</p>
                            <p class="day">{{story.date.day}}</p>
                            <p class="year">{{story.date.year}}</p>
                        </div>
                        <div class="eng__caption" v-scroll-reveal="{ distance: '50px', origin: 'left', delay: 500, opacity: 0}">
                            <p class="author">{{story.author}}</p>
                            <p class="title">{{story.title}}</p>
                        </div>
                    </router-link>
                </div>
            </div>
        </transition>

        <!-- Latest News -->
        <transition name="pop-fade">
            <div class="stories__news active" v-if="isLatestNews && data">
                <div class="stories__news__media" v-if="activeStory">
                    <div class="stories__news__media-wrap">
                        <div class="stories__news__media-wrap__image" :style="'background-image: url(' + activeStory.asset.path + ')'" v-if="activeStory.asset.type == 'image'"></div>
                        <div class="stories__news__media-wrap__video" v-else>
                            <div class="video-wrap">
                                <video autoplay muted loop playsinline>
                                    <source type="video/mp4" :src="activeStory.asset.path">
                                </video>
                            </div>
                        </div>
                        <div class="date">
                            <p class="month">{{activeStory.date.month}}</p>
                            <p class="day">{{activeStory.date.day}}</p>
                            <p class="year">{{activeStory.date.year}}</p>
                        </div>
                    </div>
                </div>
                <div class="stories__news__list">
                    <div id="news-list-slide">
                        <ul>
                            <li v-for="(story, i) in data.news" :key="story.id" :class="{ 'active' : activeStory.id == story.id }" @mouseenter="activeStory=story">
                                <router-link :to="story.slug" append>
                                    <div class="title-blurb">
                                        <p class="author">{{story.author}}</p>
                                        <p class="title">{{story.title}}</p>
                                    </div>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                    <div class="tints"></div>
                </div>
            </div>
        </transition>

        <!-- Mobile -->
        <transition name="pop-fade">
            <div class="stories__news-mobile active" v-if="isLatestNews && data">
                <div class="stories__news-mobile__article" v-for="(story, i) in data.news" :key="story.id">
                    <router-link :to="story.slug" append>
                        <!-- <div class="image" :style="'background-image: url(' + story.asset.path + ')'"> -->

                        <!-- why isint this correct? -->

                        <div class="image" :style="'background-image: url(' + story.asset.path + ')'" v-if="story.asset.type == 'image'">

                            <div class="date" v-if="i === 0">
                                <p class="month">{{story.date.month}}</p>
                                <p class="day">{{story.date.day}}</p>
                                <p class="year">{{story.date.year}}</p>
                            </div>

                        </div>

                        <div class="stories__news__left-image__video" v-else>
                            <div class="video-wrap">
                                <video autoplay muted loop playsinline>
                                    <source type="video/mp4" :src="story.asset.path">
                                </video>
                            </div>

                            <div class="date" v-if="i === 0">
                                <p class="month">{{story.date.month}}</p>
                                <p class="day">{{story.date.day}}</p>
                                <p class="year">{{story.date.year}}</p>
                            </div>

                        </div>    

<!-- 
                            <div class="date" v-if="i === 0">
                                <p class="month">{{story.date.month}}</p>
                                <p class="day">{{story.date.day}}</p>
                                <p class="year">{{story.date.year}}</p>
                            </div> -->
                        <!-- </div> -->



                        <div class="caption">
                            <div class="top">
                                <p class="date-numeric">{{story.date.numeric}}</p>
                                <p class="author">{{story.author}}</p>
                            </div>
                            <p class="title">{{story.title}}</p>
                        </div>
                    </router-link>
                </div>
            </div>
        </transition>

    </section>
</template>

<script>
    import axios from 'axios'
    import Vue from 'vue'
    import SizingHelper from './../helpers/SizingHelper'
    import Meta from '../mixins/Meta.js'

    export default {
        mixins: [Meta],
        props: ['isLatestNews'],
        data() {
            return {
                data: null,
                activeStory: null
            }
        },
        mounted() {
            $(window).scrollTop(0);
            this.loadData();
            this.$body.addClass('stories-landing');
            window.addEventListener('resize', this.onResize, false);
        },
        methods: {
            loadData() {
                axios.get(this.$json('/data/stories.json'))
                    .then(response => {
                        this.data = response.data;
                        this.setMetaData(this.data.meta);
                        // console.log(this.data);
                        this.activeStory = this.data.news[0]; // make first story active
                        

                        Vue.nextTick(() => {
                            //this.revealNews();
                            this.sizeMediaOnDesktop();
                        });


                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
            sizeMediaOnDesktop() {

                if (window.innerWidth < 1200) return;

                let $wrap = $('.stories__news__media');
                let $mediaWrap = $('.stories__news__media-wrap');

                $wrap.width('50%');
                $mediaWrap.css({
                    width: 'auto',
                    height: 'auto',
                });

                var obj = SizingHelper.calculateAspectRatioFit(80, 100, $wrap.width(), $wrap.height());

                $wrap.width('auto');
                $mediaWrap.css({
                    width: obj.width,
                    height: obj.height,
                });
            },
            onResize() {
                this.sizeMediaOnDesktop();
            }
        },
        watch: {
            activeStory() {
                // need to tell videos to reload after changing src
                if (this.activeStory && this.activeStory.asset.type == "video") {
                    $('video').each(function(i, el) {
                        var $curVideo = $(el);
                        var src = $curVideo.find('source').attr('src');
                        $curVideo.get(0).load();
                    });
                }
            },
        },
        beforeDestroy() {
            this.$body.removeClass('stories-landing');
            window.removeEventListener('resize', this.onResize);
        }
    }
</script>